import axios from "axios";

// desarrollo

// const config = {
//   URI: "http://api.abc.test/api/public",
// };

// producción
const config = {
  URI: "https://5n019pig1l.execute-api.us-east-1.amazonaws.com/prod/api/public",
};

const instance = axios.create({
  baseURL: config.URI,
  headers: {
    "Content-Type": "application/json",
  },
});

const Api = async function (tipoconsulta, type, filtros) {
  return new Promise((resp, err) => {
    // El acción en GET representa el PROJECT
    const data = filtros || {};
    data.type = type;
    instance
      .post(tipoconsulta, data)
      .then(function (response) {
        resp(response.data);
      })
      .catch(function (error) {
        console.log(error);
        err(error);
      });
  });
};

const Auth = async function (tipo, datos) {
  return instance.post(tipo, datos);
};

export { Api, Auth };
