import React from "react";
import { Api } from "../config/Api";
import Campos from "./campos";

const Mensaje = (props) => {
  return (
    props.errorForm && <span className="tag-error">{props.textoError}</span>
  );
};

function Formulario(props) {
  const { campos, textoBoton, params, idFormulario } = props;
  const [errorForm, setErrorForm] = React.useState(false);
  const [textoError, setTextoError] = React.useState("No pudimos registrarte");
  const [enviando, setEnviando] = React.useState(false);

  const Campo = (propi) => {
    return <Campos key={"ids" + propi.index} campos={propi.campo} />;
  };

  function registrarme(event) {
    event.preventDefault();
    setEnviando(true);
    try {
      const formLleno = {};
      const datos = new FormData(event.currentTarget);
      datos.forEach((value, campo, veps) => {
        formLleno[campo] = value;
      });

      Api("paginaregistro", "RegisterAfiliado", {
        data: formLleno,
        cuenta: params.cuenta,
        afiliado: params.ide,
        idFormulario: idFormulario,
      })
        .then((ress) => {
          if (ress.success) {
            window.location =
              "https://" +
              props.dominio +
              `?user=${ress.idusuario}&tt=${ress.token}`;
          } else {
            setErrorForm(true);
            setTextoError(ress.msg);
            setTimeout(() => {
              setErrorForm(false);
            }, 3000);
            setEnviando(false);
          }
        })
        .catch((e) => {
          setEnviando(false);
          console.log("houston");
        });
    } catch (error) {
      console.log(error);
      setEnviando(false);
    }
  }

  return (
    <>
      <Mensaje errorForm={errorForm} textoError={textoError} />
      <form key="oka" onSubmit={registrarme}>
        {campos.map((campo, index) => {
          return <Campo key={"ids" + index} campo={campo} index={index} />;
        })}
        <button disabled={enviando} style={{ marginTop: 20 }}>
          {textoBoton}
        </button>
      </form>
    </>
  );
}

export default Formulario;
