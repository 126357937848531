import "./App.css";
import React, { useState } from "react";
import { Api } from "./config/Api";
import { useLocation, useParams } from "react-router-dom";
import ErrorUrl from "./ErrorUrl";
import Formulario from "./componentes/formulario";
import Loader from "react-loader-spinner";

function App() {
  const [error, setError] = useState(false);
  const [pensando, setPensando] = useState(true);
  const [idFormulario, setIdFormulario] = useState("");
  const [logo, setLogo] = useState("");
  const [contenido, setContenido] = useState({ frase: ".", autor: "...." });
  const [nombreAfiliado, setNombreAfiliado] = React.useState("");
  const [campos, setCampos] = React.useState([]);
  const [textoBoton, setTextoBoton] = React.useState("Registrarme");
  const [dominio, setDominio] = React.useState("");

  let { cuenta, ide } = useParams();

  const history = useLocation();
  const params = useParams();
  React.useEffect(() => {
    Api("paginaregistro", "Get", { ideCuenta: cuenta, ideAfiliado: ide }).then(
      (res) => {
        setPensando(false);
        const data = res.data;
        if (!res.data) {
          setError(true);
        } else {
          setLogo(data.logo);
          setDominio(data.dominio);
          setNombreAfiliado(data.nombreAfiliado);
          setCampos(data.formulario.campos);
          setIdFormulario(data.formulario._id);
          setContenido(data.contenido);
          setTextoBoton(data.formulario.boton || "Registrarme");
        }
      }
    );
  }, []);

  return (
    <>
      {error ? (
        <ErrorUrl />
      ) : pensando ? (
        <div style={{height: "100vh", width: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
          <Loader type="Rings" color="#000" height={100} width={100} />
        </div>
      ) : (
        <div>
          <main style={{ height: "100vh" }}>
            <div className="base">
              <div className="baseformulario">
                <div style={estilos.cajonformu}>
                  <span className="tituloform">Hola!</span>
                  <span className="subtituloform">
                    {nombreAfiliado !== "Empresa" && (
                      <span>
                        Soy <strong>{nombreAfiliado}</strong> y
                      </span>
                    )}
                    <span> quiero que seas parte de mi equipo.</span>
                  </span>

                  {campos.length > 0 && (
                    <Formulario
                      params={params}
                      textoBoton={textoBoton}
                      campos={campos}
                      idFormulario={idFormulario}
                      dominio={dominio}
                    />
                  )}
                </div>
              </div>
              <div className="basefraseepica">
                <div style={estilos.cajonfrase}>
                  {logo !== "" && (
                    <img alt="logo" className="logo" src={logo} />
                  )}
                  {contenido.frase ? (
                    <span className="fraseepica">{contenido.frase}</span>
                  ) : (
                    <span className="fraseepica">
                      Todos tus sueños pueden hacerse realidad si tienes el
                      coraje de perseguirlos
                    </span>
                  )}

                  {contenido.frase ? (
                    <strong style={{ marginTop: 10 }}>{contenido.autor}</strong>
                  ) : (
                    <strong style={{ marginTop: 10 }}>Walt Disney</strong>
                  )}
                </div>
              </div>
            </div>
          </main>
        </div>
      )}
    </>
  );
}

const estilos = {
  cajonfrase: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
  },

  cajonformu: {
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    paddingBottom: 10,
    alignItems: "center",
    marginBottom: 40,
    width: "100%",
  },
};

export default App;
