import "./App.css";
import React from "react";

function ErrorUrl(props) {
  return (
    <div>
      <main style={{ height: "100vh" }}>
        <div className="base">
          <div style={estilos.baseformu} className="baseformulario">
            <div style={estilos.cajonformu}>
              <span style={estilos.tituloform}>URL incorrecta</span>
              <span style={estilos.subtituloform}>
                Revisa que la url <strong>{window.location.href}</strong> esté
                correctamente escrita
              </span>
            </div>
          </div>
          <div style={estilos.basefraseepica} className="basefraseepica">
            <div style={estilos.cajonfrase}>
              
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

const estilos = {
  basefraseepica: {
    display: "flex",
    flexDirection: "column",
    width: 400,
    padding: 30,
    justifyContent: "center",
    justifyItems: "center",
    alignItems: "center",
    alignContent: "center",
  },
  cajonfrase: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
  },
  baseformu: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: 40,
    paddingRight: 40,
    justifyContent: "center",
    justifyItems: "center",

    alignContent: "center",
  },
  cajonformu: {
    display: "flex",
    flexDirection: "column",
  },
  tituloform: {
    fontSize: 60,
    fontWeight: "bold",
  },
  subtituloform: {
    fontWeight: 200,
    fontSize: 20,
  },
};

export default ErrorUrl;
